.jumbotron .container {
  max-width: 40rem;
}

.jumbotron {
  background-color: #f0eeee;
}

.required {
  color: red;
  padding-left: 5px;
}

.required-label {
  font-size: 12px;
  font-style: italic;
  margin-top: 15px;
}

.google {
  background-color: #dd4b39;
  color: white;
}

.footer {
  color: #747272;
  font-size: 11px;
  letter-spacing: .2px;
  font-weight: 100;
  margin-top: 25px;
  position: relative!important;
}
.form-group {
  margin-bottom: 0px;
}

.d-flex {
  padding: 15px;
}

.form-margin {
  border-radius: 15px;
  margin-left: 0px;
  margin-right: 0px;
}

.bg-light {
  background-color: #f0eeee!important;
}

img {
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
}

.pb-5, .py-5 {
  padding-bottom: 0rem!important;
}

.container, .container-lg, .container-md, .container-sm {
  max-width: 800px;
}

.card-title {
  text-transform: capitalize;
  text-align: left;
}

.card-sub-title {
  font-size: 20px;
}

.card {
  border-radius: 10px!important;
  background-color: #8a8a8a!important;
  padding-top: 10px;
}

.card-header {
  font-size: 16px;
  font-family: 'Google Sans', Roboto, Helvetica, Arial, sans-serif;
  color: white;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.card-body {
  border-bottom-left-radius: 10px!important;
  border-bottom-right-radius: 10px!important;
  background-color: #fff!important;
  padding: 20px;
}

.card-body-question {
  border-radius: 10px!important;
  background-color: #fff!important;
  padding: 20px;
  border: 1px solid #8a8a8a;
}

body {
  background-color: #f0eeee;
  white-space: pre-wrap;
  font-family: Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.question {
  font-size: 16px;
  letter-spacing: .1px;
  margin-bottom: 15px;
  font-family: 'Google Sans', Roboto, Helvetica, Arial, sans-serif;
}

.radio {
  padding-left: 5px;
}

.text-small {
  font-size: 12px;
  font-weight: 200;
  letter-spacing: .3px;
  font-style: italic;
  width: 100%;
  background-color: #f8ead475;
  padding-left: 10px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
}
